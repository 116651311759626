class Utils {

    static numberText(n, decimals) {

        let amountText = ["", "Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion"];

        let illions = Math.floor(Math.log(n) / Math.log(1000));

        let supportedIllions = Math.max(0, Math.min(illions, amountText.length - 1));

        let floor = Math.pow(1000, supportedIllions);

        return (n / floor).toFixed(decimals || 0) + " " + amountText[supportedIllions];

    }

}

export default Utils;