class API {

    static async get() {

        let key = "cfa36c97-499f-4ab3-adfa-09e29079db12";

        let params = {
            method: "POST",
            mode: "cors",
            cache: "default",

            body: JSON.stringify({
                "code": "SHIB",
                "meta": true,
                "currency": "USD"
            }),

            headers: {
                "X-API-Key": key,
                "Content-Type": "application/json",
            }
        }

        let response = await fetch("https://api.livecoinwatch.com/coins/single", params);

        return response.json();

    }

    static async getAlt() {

        let response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=shiba-inu&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&include_last_updated_at=true");

        return response.json();

    }

}

export default API;