import './App.css';
import React from 'react'
import API from './API.js'
import Utils from './Utils.js';

let globalRefreshInterval;
const refreshTime = 20000;

class App extends React.Component {

  constructor() {
    super();

    this.state = {
      testAmount: 0,
      rocketProp: 0,
      startAmount: 0.000001,
      endAmount: 0.01,
      thresholds: [],
    };

    this.canvasRef = React.createRef();

  }

  componentDidMount() {

    API.getAlt().then((response) => {

      response = response["shiba-inu"];

      let reformatted = {

        rate: response.usd,
        cap: response.usd_market_cap,
        circulatingSupply: response.usd_market_cap / response.usd,
      }

      this.update(reformatted);

    });

    clearInterval(globalRefreshInterval);

    globalRefreshInterval = setInterval(() => {

      API.getAlt().then((response) => {

        response = response["shiba-inu"];

        let reformatted = {

          rate: response.usd,
          cap: response.usd_market_cap,
          circulatingSupply: response.usd_market_cap / response.usd,
        }

        this.update(reformatted);

      });

    }, refreshTime);

  }

  getPennyProp(logMode) {

    if (!this.state.currentData) return 0;

    let rate = this.state.currentData.rate;

    if (logMode) {

      return Math.log(0.01) / Math.log(0.01 - rate);

    } else {

      return rate / 0.01;

    }

  }

  getIntLog(rate) {

    if (rate === 0) return 0;

    let log = Math.log(rate) / Math.log(10);

    return log >= 0 ? Math.floor(log) : -Math.ceil(-log);

  }

  getNextTargetText(rate) {

    if (rate === 0) return "0";

    let log = this.getIntLog(rate);

    return Math.pow(10, log + 1).toFixed(Math.max(0, -log - 1));

  }

  update(newData) {

    let rate = newData.rate;

    let startLog = this.getIntLog(this.state.startAmount);
    let currLog = this.getIntLog(rate);
    let targetLog = this.getIntLog(this.state.endAmount);

    let thresholds = [];

    let sum = 0;
    let totalProp = 0;

    for (let log = startLog; log <= targetLog; log++) {

      let targetText = Math.pow(10, log).toFixed(Math.max(0, -log));

      let targetRate = parseFloat(targetText);

      let prop = 0;

      let type;

      if (log === currLog + 1) { // this is the current target

        prop = 1 - (targetRate - rate) / targetRate;

        type = "current";

      } else if (log < currLog + 1) { // this is a past target

        prop = 1;

        type = "past";

      } else { // this is a future target

        type = "future";

      }

      sum++;
      totalProp += prop;

      thresholds.push({
        prop,
        targetRate,
        targetText,
        type
      });

    }

    this.setState({ rocketProp: totalProp / sum, thresholds, currentData: newData });

  }

  getTargetElements() {

    let circSupp = this.state.currentData ? this.state.currentData.circulatingSupply : 0;

    let elements = [];

    for (let thresh of this.state.thresholds) {

      let ele = <div key={thresh.targetText} className={"tile " + thresh.type} style={{ marginBottom: 10 }}>
        <div >
          <h2 style={{ display: "inline", }}>{thresh.type === "past" ? <del>{"$" + thresh.targetText}</del> : ("$" + thresh.targetText)}</h2>
          <span style={{ float: "right" }}>{" (" + (thresh.prop * 100).toFixed(2) + "%)"}</span>
        </div>
        <div style={{ marginBottom: 5 }}>
          {"Projected Market Cap: $" + Utils.numberText(thresh.targetRate * circSupp)}
        </div>
        <div style={{ marginBottom: 5 }}>
          {"Projected Value: $" + Utils.numberText(thresh.targetRate * this.state.testAmount, 2)}
        </div>
        <div>
          <div style={{ width: "100%", border: "1px solid #505050" }}>
            <div style={{ width: thresh.prop * 100 + "%", height: 10, backgroundColor: "lightgreen" }} />
          </div>
        </div>
      </div>

      elements.unshift(ele);

    }

    return elements;


  }

  drawCanvas() {

    let canvas = this.canvasRef.current;

    let rect = canvas.getBoundingClientRect();

    canvas.width = rect.width * 2;
    canvas.height = rect.height * 2;

    let ctx = canvas.getContext("2d");

    ctx.beginPath();

    ctx.moveTo(0, 0);
    ctx.lineTo(canvas.width, canvas.height);

    ctx.stroke();

    var moonImg = document.getElementById("moonImage");
    var rocketImg = document.getElementById("rocketImage");
    var earthImg = document.getElementById("earthImage");

    ctx.filter = `blur(1px)`;

    let moonImgSize = {
      width: 200,
      height: 200
    }

    let rocketImgSize = {
      width: 100,
      height: 200
    }

    let earthImgSize = {
      width: 500,
      height: 500
    }

    // moon
    ctx.drawImage(
      moonImg,
      0,
      0,
      moonImg.width,
      moonImg.height,
      (canvas.width - moonImgSize.width) / 2,
      50,
      moonImgSize.width,
      moonImgSize.height
    );


    // earth
    ctx.drawImage(
      earthImg,
      0,
      0,
      earthImg.width,
      earthImg.height,
      (canvas.width - earthImgSize.width) / 2,
      canvas.height - earthImgSize.height / 2,
      earthImgSize.width,
      earthImgSize.height
    );

    // rocket
    ctx.drawImage(
      rocketImg,
      0,
      0,
      rocketImg.width,
      rocketImg.height,
      (canvas.width - rocketImgSize.width) / 2,
      canvas.height - (this.state.rocketProp) * (canvas.height - 100) - (rocketImgSize.height) / 2 - 50,
      rocketImgSize.width,
      rocketImgSize.height
    );

  }

  componentDidUpdate() {

    this.drawCanvas();

  }

  render() {

    let rate = this.state.currentData ? this.state.currentData.rate : 0;

    let cap = this.state.currentData ? this.state.currentData.cap : 0;

    let circSupp = this.state.currentData ? this.state.currentData.circulatingSupply : 0;

    return (
      <div style={{ maxWidth: 2000, margin: "0 auto" }}>

        <div style={{ display: "flex", marginBottom: 20 }}>

          <div style={{ width: 60 }}>
            <img src="shiba_logo.svg" style={{ width: "100%", display: "block", margin: "auto" }} />
          </div>

          <div style={{ display: "flex" }}>
            <h1 style={{ textAlign: 'center', display: "block", margin: "auto auto auto 20px" }}>SHIB Ground Control</h1>
          </div>

        </div>

        <div style={{ display: "flex", marginBottom: 10 }}>
          <div style={{ flex: 1, marginRight: 10 }}>
            <h1 style={{ textAlign: 'center' }}>{"$" + parseFloat(rate.toFixed(8))}</h1>
            <h3 style={{ textAlign: 'center' }}>{"Market Cap: $" + Utils.numberText(cap, 1)}</h3>
          </div>

          <div style={{ flex: 2 }}>
            <input style={{ display: "inline-block", flex: 1 }} type="number" placeholder="Preview SHIB balance" onChange={(e) => this.setState({ testAmount: parseFloat(e.target.value) || 0 })}></input>
          </div>

        </div>

        <div style={{ display: 'flex', marginBottom: 50 }}>
          <div style={{ flex: 1, marginRight: 10 }}>
            <canvas ref={this.canvasRef} style={{ width: "100%", height: "100%", backgroundColor: "black", height: 800, /*background: "url(stars.jpg)"*/ backgroundColor: "black" }}></canvas>
            <div className="tile">
              <h4>{"Distance Traveled: " + (this.state.rocketProp * 238900).toLocaleString() + " miles"}</h4>
              <h4>{"Remaining Distance: " + ((1 - this.state.rocketProp) * 238900).toLocaleString() + " miles"}</h4>
            </div>
          </div>
          <div style={{ flex: 2 }}>
            {this.getTargetElements(0.000001, rate, 0.01, circSupp)}
          </div>
        </div>

        {/*<input type="range" min={0} max={100} onChange={(e) => this.setState({ rocketProp: e.target.value / 100 })} />*/}

        <div className="tile">

          <h1>How This Works</h1>
          <p>SHIB Ground Control is a visualization of SHIB's road to 1 cent USD. In the section above, there are multiple price milestones representing each zero that needs to be taken out to reach the price target. 
          On the left is a graphic showing the progress towards one cent as a trip to the moon. The progress scales with the removal of zeroes from the price of SHIB. You can enter your SHIB balance in the text input above to see how much it will be worth at each milestone.</p>

          

          {/*<br/>
          
          <h1>How to Contribute</h1>

          <br/>

          <h2>Buy and Hold</h2>
          <p>No matter if you are new to SHIB or have been holding for months, the best way to drive the price of SHIB is to buy and hold. Be sure to use the value calculator to motivate yourself to keep holding even when the price rises exponentially. That being said, only invest what you can afford to lose. Crypto is highly volatile, and you are participating at your own risk.</p>

          <br/>

          <h2>Participate in Burns</h2>
          <p>SHIB's circulating supply is extremely high compared to other crypto. It may not be feasible to reach one cent purely through holding. Luckily, we as a community have the ability to "burn" coins by sending them to inaccessible wallets in the blockchain.</p>*/}


        </div>

      </div>
    )
  };
}

export default App;
